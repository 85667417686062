export default () => {
  const modalBtns = document.querySelectorAll('.modal-btn');
  const modal = document.getElementById('modal');
  const closeModalBtn = document.querySelector('.close-modal-btn');
  const overlay = document.getElementById('overlay');

  const nameInput = document.getElementById('name-input');
  const phoneInput = document.getElementById('phone-input');

  const submitMessage = document.getElementById('modal-submit');
  const submitButton = document.getElementById('submit-button');
  const submitClose = document.querySelectorAll('.close-modal-submit-btn');

  const closeModal = () => {
    modal.classList.remove('opacity-100', 'visible');
    modal.classList.add('opacity-0', 'invisible');
    overlay.classList.remove('opacity-100', 'visible');
    overlay.classList.add('opacity-0', 'invisible');
    submitMessage.classList.remove('opacity-100', 'visible');
    submitMessage.classList.add('opacity-0', 'invisible');
  };
  const closeSubmitMessage = (e) => {
    submitMessage.classList.remove('opacity-100', 'visible');
    submitMessage.classList.add('opacity-0', 'invisible');
    overlay.classList.remove('opacity-100', 'visible');
    overlay.classList.add('opacity-0', 'invisible');
    e.preventDefault();
  };

  for (let i = 0; i < modalBtns.length; i++) {
    const btn = modalBtns[i];
    btn.addEventListener('click', () => {
      modal.classList.remove('opacity-0', 'invisible');
      modal.classList.add('opacity-100', 'visible');
      overlay.classList.remove('opacity-0', 'invisible');
      overlay.classList.add('opacity-100', 'visible');
    });
  }
  if (submitMessage.classList.contains('opacity-100')) {
    overlay.addEventListener('click', closeSubmitMessage);
  }
  closeModalBtn.addEventListener('click', closeModal);
  overlay.addEventListener('click', closeModal);

  // form mask
  const phoneMask = IMask(phoneInput, {
    mask: '+{7}(000)000-00-00',
  });
  const nameMask = IMask(nameInput, {
    mask: /^([а-яА-ЯёЁa-zA-Z---" "])+$/i,
  });
  // Validation
  const validateForm = () => {
    if (nameInput.value.length === 0) {
      nameInput.classList.remove('input');
      nameInput.classList.add('input-error');
      return false;
    }
    if (nameInput.value.length === 0) {
      nameInput.classList.remove('input');
      nameInput.classList.add('input-error');
      return false;
    }
    if (nameInput.value[0] === ' ' || nameInput.value[0] === '-') {
      nameInput.value = '';
      nameInput.classList.remove('input');
      nameInput.classList.add('input-error');
      return false;
    }
    if (phoneInput.value.length != 16) {
      phoneInput.classList.remove('input');
      phoneInput.classList.add('input-error');
      return false;
    }
    return true;
  };

  nameInput.addEventListener('focus', (e) => {
    nameInput.classList.remove('input-error');
    nameInput.classList.add('input');
  });
  phoneInput.addEventListener('focus', (e) => {
    phoneInput.classList.remove('input-error');
    phoneInput.classList.add('input');
  });

  const clearInputs = () => {
    nameInput.classList.remove('input-error');
    nameInput.classList.add('input');
    phoneInput.classList.remove('input-error');
    phoneInput.classList.add('input');
    nameInput.value = '';
    phoneInput.value = '';
    // hourInput.value = "";
    // minuteInput.value = "";
    // callLater.classList.remove("active");
    // callNow.classList.add("active");
    // timeInput.classList.remove("open");
  };

  submitButton.addEventListener('click', (e) => {
    if (validateForm()) {
      submitMessage.classList.remove('opacity-0', 'invisible');
      submitMessage.classList.add('opacity-100', 'visible');
      setTimeout(() => {
        submitMessage.classList.remove('opacity-100', 'visible');
      }, 5000);
      modal.classList.remove('opacity-100', 'visible');
      modal.classList.add('opacity-0', 'invisible');
      clearInputs();
    }
    e.preventDefault();
  });
  for (let i = 0; i < submitClose.length; i++) {
    const element = submitClose[i];
    element.addEventListener('click', closeSubmitMessage);
  }

  const modalBtnsPromo = document.querySelectorAll('.modal-btn-promo');
  const modalPromo = document.getElementById('modal-promo');
  const closeModalBtnPromo = document.querySelector('.close-modal-btn-promo');

  const nameInputPromo = document.getElementById('name-input-promo');
  const phoneInputPromo = document.getElementById('phone-input-promo');

  const submitMessagePromo = document.getElementById('modal-submit-promo');
  const submitButtonPromo = document.getElementById('submit-button-promo');
  const submitClosePromo = document.querySelectorAll(
    '.close-modal-submit-btn-promo'
  );

  const closeModalPromo = () => {
    modalPromo.classList.remove('opacity-100', 'visible');
    modalPromo.classList.add('opacity-0', 'invisible');
    overlay.classList.remove('opacity-100', 'visible');
    overlay.classList.add('opacity-0', 'invisible');
    submitMessagePromo?.classList.remove('opacity-100', 'visible');
    submitMessagePromo?.classList.add('opacity-0', 'invisible');
  };
  const closeSubmitMessagePromo = (e) => {
    submitMessagePromo?.classList.remove('opacity-100', 'visible');
    submitMessagePromo?.classList.add('opacity-0', 'invisible');
    overlay.classList.remove('opacity-100', 'visible');
    overlay.classList.add('opacity-0', 'invisible');
    e.preventDefault();
  };

  for (let i = 0; i < modalBtnsPromo.length; i++) {
    const btn = modalBtnsPromo[i];
    btn.addEventListener('click', () => {
      modalPromo.classList.remove('opacity-0', 'invisible');
      modalPromo.classList.add('opacity-100', 'visible');
      overlay.classList.remove('opacity-0', 'invisible');
      overlay.classList.add('opacity-100', 'visible');
    });
  }
  if (submitMessagePromo?.classList.contains('opacity-100')) {
    overlay.addEventListener('click', closeSubmitMessage);
  }
  closeModalBtnPromo?.addEventListener('click', closeModalPromo);
  overlay.addEventListener('click', closeModalPromo);

  // form mask
  const phoneMaskPromo = IMask(phoneInputPromo, {
    mask: '+{7}(000)000-00-00',
  });
  const nameMaskPromo = IMask(nameInputPromo, {
    mask: /^([а-яА-ЯёЁa-zA-Z---" "])+$/i,
  });
  // Validation
  const validateFormPromo = () => {
    if (nameInputPromo.value.length === 0) {
      nameInputPromo.classList.remove('input');
      nameInputPromo.classList.add('input-error');
      return false;
    }
    if (nameInputPromo.value.length === 0) {
      nameInputPromo.classList.remove('input');
      nameInputPromo.classList.add('input-error');
      return false;
    }
    if (nameInputPromo.value[0] === ' ' || nameInputPromo.value[0] === '-') {
      nameInputPromo.value = '';
      nameInputPromo.classList.remove('input');
      nameInputPromo.classList.add('input-error');
      return false;
    }
    if (phoneInputPromo.value.length != 16) {
      phoneInputPromo.classList.remove('input');
      phoneInputPromo.classList.add('input-error');
      return false;
    }
    return true;
  };

  nameInputPromo.addEventListener('focus', (e) => {
    nameInputPromo.classList.remove('input-error');
    nameInputPromo.classList.add('input');
  });
  phoneInputPromo.addEventListener('focus', (e) => {
    phoneInputPromo.classList.remove('input-error');
    phoneInputPromo.classList.add('input');
  });

  const clearInputsPromo = () => {
    nameInputPromo.classList.remove('input-error');
    nameInputPromo.classList.add('input');
    phoneInputPromo.classList.remove('input-error');
    phoneInputPromo.classList.add('input');
    nameInputPromo.value = '';
    phoneInputPromo.value = '';
    // hourInput.value = "";
    // minuteInput.value = "";
    // callLater.classList.remove("active");
    // callNow.classList.add("active");
    // timeInput.classList.remove("open");
  };

  submitButtonPromo.addEventListener('click', (e) => {
    if (validateFormPromo()) {
      submitMessagePromo?.classList.remove('opacity-0', 'invisible');
      submitMessagePromo?.classList.add('opacity-100', 'visible');
      setTimeout(() => {
        submitMessagePromo?.classList.remove('opacity-100', 'visible');
      }, 5000);
      modalPromo.classList.remove('opacity-100', 'visible');
      modalPromo.classList.add('opacity-0', 'invisible');
      clearInputsPromo();
    }
    e.preventDefault();
  });
  for (let i = 0; i < submitClosePromo.length; i++) {
    const element = submitClosePromo[i];
    element.addEventListener('click', closeSubmitMessagePromo);
  }
};
