export default () => {
  const header = document.getElementById('header');

  let touchStartY = 0;
  let isFixedHeader = false;

  const onScroll = () => {
    if (window.pageYOffset >= 1000) {
      isFixedHeader = true;
    } else if (window.pageYOffset < 1000) {
      isFixedHeader = false;
    }
  };

  document.addEventListener('wheel', (event) => {
    if (event.deltaY < 0 && isFixedHeader) {
      setTimeout(() => {
        // header.classList.remove('-translate-y-full');
        // header.classList.add('translate-y-0');
        header.classList.remove('transform-header');
      }, 500);
    } else {
      setTimeout(() => {
        // header.classList.remove('translate-y-0');
        // header.classList.add('-translate-y-full');
        header.classList.add('transform-header');
      }, 500);
    }
  });

  document.addEventListener('touchstart', (event) => {
    touchStartY = event.touches[0].clientY;
  });

  document.addEventListener('touchmove', (event) => {
    const currentY = event.touches[0].clientY;
    if (currentY > touchStartY && isFixedHeader) {
      setTimeout(() => {
        // header.classList.remove('-translate-y-full');
        // header.classList.add('translate-y-0');
        header.classList.remove('transform-header');
      }, 500);
    } else {
      setTimeout(() => {
        // header.classList.remove('translate-y-0');
        // header.classList.add('-translate-y-full');
        header.classList.add('transform-header');
      }, 500);
    }
  });
  document.addEventListener('touchmove', onScroll);
  document.addEventListener('scroll', onScroll);
};
