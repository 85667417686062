export default () => {
  const loader = document.getElementById('preloader');
  const progress = document.getElementById('progress');

  window.addEventListener('load', () => {
    document.body.style.overflow = 'hidden';

    setTimeout(() => {
      window.scroll(0, 0);
      loader.classList.add('opacity-0', 'invisible');
      document.body.classList.remove('overflow-hidden');
      // document.body.style.overflow = 'auto';
    }, 1250);

    // let width = 2;
    // const loading = setInterval(frame, 10);
    // function frame() {
    //   if (width >= 100) {
    //     clearInterval(loading);
    //   } else {
    //     width++;
    //     progress.innerHTML = width + '%';
    //   }
    // }
  });
};
