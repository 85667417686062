import image from '../img/omega-map-point.png';
export default () => {
  ymaps.ready(function () {
    var myMap = new ymaps.Map(
        'map',
        {
          center: [44.947982, 34.11991],
          zoom: 14,
        },
        {
          searchControlProvider: 'yandex#search',
        }
      ),
      // Создаём макет содержимого.
      myPlacemark = new ymaps.Placemark(
        myMap.getCenter(),
        {
          hintContent: '',
          balloonContent: '',
        },
        {
          // Опции.
          // Необходимо указать данный тип макета.
          iconLayout: 'default#image',
          // Своё изображение иконки метки.
          iconImageHref: image,
          // Размеры метки.
          iconImageSize: [115, 115],
          // Смещение левого верхнего угла иконки относительно
          // её "ножки" (точки привязки).
          iconImageOffset: [-70, -70],
          // iconImageOffset: [-100, -100],
        }
      );
    myMap.behaviors.disable('scrollZoom');
    myMap.controls.remove('searchControl');
    myMap.controls.remove('geolocationControl');
    myMap.controls.remove('trafficControl');
    myMap.controls.remove('fullscreenControl');
    myMap.controls.remove('typeSelector');
    myMap.geoObjects.add(myPlacemark);
  });
};
