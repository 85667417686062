export default () => {
  const cards = document.querySelectorAll('.card');

  const cardAdaptation = () => {
    for (let i = 0; i < cards.length; i++) {
      const card = cards[i];
      const cardContent = card.querySelector('.card-text');
      if (cardContent && cardContent.scrollHeight > card.scrollHeight * 0.8) {
        card.style.transition = 'all 0.5s ease 0s';

        card.addEventListener('mouseover', () => {
          card.style.minHeight = cardContent.scrollHeight + 170 + 'px';
        });

        card.addEventListener('mouseleave', () => {
          if (window.innerWidth >= 1536) {
            card.style.minHeight = '520px';
          } else if (window.innerWidth >= 768) {
            card.style.minHeight = '450px';
          } else {
            card.style.minHeight = '300px';
          }
        });
      }
    }
  };

  window.addEventListener('load', cardAdaptation);
  window.addEventListener('resize', cardAdaptation);
};
