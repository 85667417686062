export default () => {
  const stroke = document.querySelectorAll('.stroke');

  const stroke1 = document.querySelector('.stroke-1');
  const stroke2 = document.querySelector('.stroke-2');
  const stroke3 = document.querySelector('.stroke-3');
  const stroke4 = document.querySelector('.stroke-4');
  const stroke5 = document.querySelector('.stroke-5');
  const stroke6 = document.querySelector('.stroke-6');
  const stroke7 = document.querySelector('.stroke-7');
  const stroke8 = document.querySelector('.stroke-8');
  const stroke9 = document.querySelector('.stroke-9');
  const stroke10 = document.querySelector('.stroke-10');
  const stroke11 = document.querySelector('.stroke-11');
  const stroke12 = document.querySelector('.stroke-12');

  const Visible = function (target) {
    // Все позиции элемента
    const targetPosition = {
        top: window.pageYOffset + target.getBoundingClientRect().top,
        left: window.pageXOffset + target.getBoundingClientRect().left,
        right: window.pageXOffset + target.getBoundingClientRect().right,
        bottom: window.pageYOffset + target.getBoundingClientRect().bottom,
      },
      // Получаем позиции окна
      windowPosition = {
        top: window.pageYOffset,
        left: window.pageXOffset,
        right: window.pageXOffset + document.documentElement.clientWidth,
        bottom: window.pageYOffset + document.documentElement.clientHeight,
      };

    if (
      targetPosition.bottom > windowPosition.top && // Если позиция нижней части элемента больше позиции верхней чайти окна, то элемент виден сверху
      targetPosition.top < windowPosition.bottom && // Если позиция верхней части элемента меньше позиции нижней чайти окна, то элемент виден снизу
      targetPosition.right > windowPosition.left && // Если позиция правой стороны элемента больше позиции левой части окна, то элемент виден слева
      targetPosition.left < windowPosition.right
    ) {
      // Если позиция левой стороны элемента меньше позиции правой чайти окна, то элемент виден справа
      // Если элемент полностью видно, то запускаем следующий код
      stroke1.style.transform = `translateX(${throttleSpeedCount(0.2)}%)`;
      stroke2.style.transform = `translateX(-${throttleSpeedCount(0.2)}%)`;
      stroke3.style.transform = `translateX(${throttleSpeedCount(0.2)}%)`;
      stroke4.style.transform = `translateX(${throttleSpeedCount(0.4)}%)`;
      stroke5.style.transform = `translateX(-${throttleSpeedCount(0.4)}%)`;
      stroke6.style.transform = `translateX(${throttleSpeedCount(0.4)}%)`;
      stroke7.style.transform = `translateX(${throttleSpeedCount(0.7)}%)`;
      stroke8.style.transform = `translateX(-${throttleSpeedCount(0.7)}%)`;
      stroke9.style.transform = `translateX(${throttleSpeedCount(0.7)}%)`;
      stroke10.style.transform = `translateX(${throttleSpeedCount(0.7)}%)`;
      stroke11.style.transform = `translateX(-${throttleSpeedCount(0.7)}%)`;
      stroke12.style.transform = `translateX(${throttleSpeedCount(0.7)}%)`;
    } else {
      // Если элемент не видно, то запускаем этот код
      return;
    }
  };

  const throttle = (type, name, object) => {
    const obj = object || window;
    let running = false;
    const func = () => {
      if (running) return;
      running = true;
      requestAnimationFrame(() => {
        obj.dispatchEvent(new CustomEvent(name));
        running = false;
      });
    };
    obj.addEventListener(type, func);
  };

  throttle('scroll', 'optimizedScroll');

  const throttleSpeedCount = (value) =>
    (window.pageYOffset - window.pageYOffset * value) / 100;

  window.addEventListener('optimizedScroll', () => {
    for (let i = 0; i < stroke.length; i++) {
      const element = stroke[i];
      Visible(element);
    }
  });
};
