import Swiper, { Navigation, Pagination, Controller, Parallax } from 'swiper';
import '../../node_modules/swiper/swiper-bundle.min.css';
export default () => {
  const imageSlider1 = new Swiper('.image-slider1', {
    speed: 2000,
    loop: true,
    navigation: {
      prevEl: '.slider1-arrow-left',
      nextEl: '.slider1-arrow-right',
    },
    pagination: {
      el: '.slider1-pagination',
      type: 'fraction',
    },
    longSwipesRatio: 0.01,
    followFinger: false,
    watchSlidesProgress: true,
    parallax: true,
    modules: [Navigation, Pagination, Controller, Parallax],
  });
  const textSlider1 = new Swiper('.text-slider1', {
    speed: 2000,
    loop: true,
    allowTouchMove: false,
    modules: [Navigation, Pagination],
  });

  imageSlider1.controller.control = textSlider1;

  const imageSlider2 = new Swiper('.image-slider2', {
    speed: 2000,
    loop: true,
    navigation: {
      prevEl: '.slider2-arrow-left',
      nextEl: '.slider2-arrow-right',
    },
    pagination: {
      el: '.slider2-pagination',
      type: 'fraction',
    },
    longSwipesRatio: 0.01,
    followFinger: false,
    watchSlidesProgress: true,
    parallax: true,
    modules: [Navigation, Pagination, Controller, Parallax],
  });

  const textSlider2 = new Swiper('.text-slider2', {
    speed: 2000,
    loop: true,
    allowTouchMove: false,
    // autoHeight: true,
    modules: [Navigation, Pagination],
  });

  imageSlider2.controller.control = textSlider2;

  const imageSlider3 = new Swiper('.image-slider3', {
    speed: 2000,
    loop: true,
    navigation: {
      prevEl: '.slider3-arrow-left',
      nextEl: '.slider3-arrow-right',
    },
    pagination: {
      el: '.slider3-pagination',
      type: 'fraction',
    },
    longSwipesRatio: 0.01,
    followFinger: false,
    watchSlidesProgress: true,
    parallax: true,
    modules: [Navigation, Pagination, Controller, Parallax],
  });
};
