export default () => {
  const menuBtns = document.querySelectorAll('.menu-btn');
  const menuBody = document.querySelector('.menu-body');
  const closeMenuBtn = document.querySelector('.close-menu-btn');

  for (let i = 0; i < menuBtns.length; i++) {
    const btn = menuBtns[i];
    btn.addEventListener('click', () => {
      menuBody.classList.remove('opacity-0', 'invisible');
      menuBody.classList.add('opacity-100', 'visible');
      // document.body.classList.add('overflow-hidden');
      document.body.style.overflow = 'hidden';
    });
  }
  closeMenuBtn.addEventListener('click', () => {
    menuBody.classList.remove('opacity-100', 'visible');
    menuBody.classList.add('opacity-0', 'invisible');
    // document.body.classList.remove('overflow-hidden');
    document.body.style.overflow = 'auto';
  });
};
