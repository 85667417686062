import preloader from './preloader.js';
import animations from './animations.js';
import slider from './slider.js';
import smoothScrollbar from './smoothScrollbar.js';
import map from './map.js';
import header from './header.js';
import menu from './menu.js';
import modal from './modal.js';
import card from './card.js';
import strokeAnimation from './strokeAnimation.js';

preloader();
animations();
slider();
smoothScrollbar();
map();
header();
menu();
modal();
strokeAnimation();
card();
