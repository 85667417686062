import AOS from 'aos';
import 'aos/dist/aos.css';
export default () => {
  AOS.init({
    once: true,
  });

  const mouse = document.getElementById('mouse');
  const mainScreen = document.getElementById('main-screen');
  const secondaryScreen = document.getElementById('secondary-screen');
  const logos = document.querySelectorAll('.logo');
  const secondaryScreenSlider = document.getElementById(
    'secondary-screen-slider'
  );
  const secondaryScreenSliderNavigation = document.getElementById(
    'secondary-screen-slider-navigation'
  );
  const mainScreenText = document.getElementById('main-screen-text');
  const mainWrapper = document.getElementById('main-wrapper');

  let touchStartY = 0;

  let isScreenCanChange = true;

  const transitionFuncFromMain = () => {
    setTimeout(() => {
      document.body.style.overflow = 'auto';
    }, 1000);
    mainScreen.classList.add('opacity-0', 'invisible');
    secondaryScreen.classList.remove('opacity-0', 'invisible');
    secondaryScreen.classList.add('opacity-100', 'visible');
    secondaryScreenSlider.classList.remove('translate-x-60', 'translate-y-60');
    secondaryScreenSliderNavigation.classList.remove('translate-y-96');
    mainScreenText.classList.add('translate-y-40');
    isScreenCanChange = false;
    setTimeout(() => {
      mainWrapper.classList.remove('overflow-hidden');
      isScreenCanChange = true;
    }, 1000);
  };
  const transitionFuncToMain = () => {
    if (isScreenCanChange) {
      document.body.style.overflow = 'hidden';
      mainScreen.classList.remove('opacity-0', 'invisible');
      mainScreen.classList.add('opacity-100', 'visible');
      secondaryScreen.classList.remove('opacity-100', 'visible');
      secondaryScreen.classList.add('opacity-0', 'invisible');
      secondaryScreenSlider.classList.add('translate-x-60', 'translate-y-60');
      secondaryScreenSliderNavigation.classList.add('translate-y-96');
      mainScreenText.classList.remove('translate-y-40');
      mainWrapper.classList.add('overflow-hidden');
    }
  };

  mouse.addEventListener('click', transitionFuncFromMain);

  document.addEventListener('wheel', (event) => {
    if (event.deltaY > 0 && window.pageYOffset === 0) {
      transitionFuncFromMain();
    } else if (event.deltaY < 0 && window.pageYOffset === 0) {
      transitionFuncToMain();
    }
  });

  document.addEventListener('touchstart', (event) => {
    touchStartY = event.touches[0].clientY;
  });

  document.addEventListener('touchmove', (event) => {
    const currentY = event.touches[0].clientY;
    if (window.pageYOffset === 0) {
      if (currentY > touchStartY) {
        transitionFuncToMain();
      } else {
        transitionFuncFromMain();
      }
    }
  });

  for (let i = 0; i < logos.length; i++) {
    const logo = logos[i];
    logo.addEventListener('click', transitionFuncToMain);
  }

  // window.addEventListener('beforeunload', () => {
  //   window.scroll(0, 0);
  // });
};
